.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #0068a1;
}
.button:active {
  background-color: #00476e;
}
@font-face {
  font-family: "FrutigerNextBold";
  src: url("/extras/fonts/722169/983d9248-775f-41ea-ad59-cee5bdbb6147.woff2") format("woff2"), url("/extras/fonts/722169/5f3f5b96-b77d-497d-a916-d483bc9c6c3f.woff") format("woff");
}
@font-face {
  font-family: "FrutigerNextLight";
  src: url("/extras/fonts/722205/fa8815a0-4d7b-4eb5-8184-9d9ff16708b2.woff2") format("woff2"), url("/extras/fonts/722205/35288d5e-681e-4b64-a930-438d2b2551d6.woff") format("woff");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #0078ba;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 22px;
  font-family: 'FrutigerNextLight', sans-serif;
  line-height: 1.27272727;
}
.unit caption {
  display: none;
}
.flag {
  background: #0078ba;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;
  position: relative;
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold .less,
.unit.fold .more {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 13px;
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  float: left;
  padding-left: 30px;
  background-size: 20px 20px;
  background-position: 0 4px;
  background-repeat: no-repeat;
  background-image: url(/images/load-blue.svg);
  text-decoration: none;
}
div.load a.load:hover,
div.load a.load:focus {
  color: #000;
  background-image: url(/images/load-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding: 5px 0;
}
.part.line {
  margin-top: 53px;
  margin-bottom: 53px;
  width: calc(100vw - 80px) !important;
  position: relative;
  left: 50%;
  margin-left: -50vw !important;
  transform: translateX(40px);
  margin-right: 0 !important;
}
@media (max-width: 1024px) {
  .part.line {
    width: calc(100vw - 40px) !important;
    transform: translateX(20px);
  }
}
@media (max-width: 639px) {
  .part.line {
    width: 100% !important;
    transform: none;
    margin-left: 0 !important;
    left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 20px;
  list-style-position: outside;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 45.546875%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #0078ba;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #0078ba;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.9);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'FrutigerNextLight', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 639px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 639px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 639px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #0078ba;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #0078ba;
  font-size: 16px;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #0068a1;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #00476e;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 639px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 639px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 639px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 639px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 639px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
  width: 34px;
  height: 34px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/search-blue.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head {
  position: static;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: static;
}
#disp.zoom div.head div.ctrl > div {
  position: absolute;
  z-index: 2;
}
#disp.zoom div.head div.ctrl div.prev {
  bottom: 28px;
  left: 30px;
}
#disp.zoom div.head div.ctrl div.next {
  right: 30px;
  bottom: 28px;
}
#disp.zoom div.head div.ctrl div.quit {
  right: 30px;
  top: 28px;
}
@media (max-width: 639px) {
  #disp.zoom div.head div.ctrl div.prev {
    bottom: 18px;
    left: 20px;
  }
  #disp.zoom div.head div.ctrl div.next {
    right: 20px;
    bottom: 18px;
  }
  #disp.zoom div.head div.ctrl div.quit {
    right: 20px;
    top: 18px;
  }
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 36px;
  height: 24px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-blue.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/arrow-right-blue.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/x2-blue.svg);
  width: 24px;
  height: 24px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 639px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'FrutigerNextLight', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 639px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 639px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 640px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 640px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 36px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  min-height: 36px;
  padding: 5px 10px;
  -webkit-appearance: none;
  border: 1px solid #000;
  font-size: 18px;
  line-height: 1.22222222;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 640px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile input {
    top: 4px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  background: transparent;
  height: 36px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 640px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 36px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 13px;
  margin-bottom: 13px;
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff;
  background-color: #0068a1;
}
.unit.form input.submit:active {
  background-color: #00476e;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 13px;
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #0068a1;
}
.two-step-verification-container a:active {
  background-color: #00476e;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.table td {
  padding: 5px 10px;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
@media (max-width: 639px) {
  .part--table .table {
    font-size: 3.4428795vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 20px;
}
.cb-layout3 #head {
  margin-top: 20px;
}
@media (max-width: 639px) {
  #head {
    margin-top: 40px;
  }
}
.scroll-animated {
  opacity: 0;
  transform: translateY(300px);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.scroll-animated.scroll-animated--active {
  opacity: 1;
  transform: translateY(0);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-size: 22px;
  font-family: 'FrutigerNextLight', sans-serif;
  line-height: 1.27272727;
  color: #000;
  overflow: hidden;
  hyphens: auto;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1440px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--footer {
  background-color: #0078ba;
  color: #fff;
  font-size: 16px;
  line-height: 1.25;
  margin-top: 60px;
}
.section--footer a {
  color: #fff;
  text-decoration: none;
}
.section--footer a:hover,
.section--footer a:focus {
  color: rgba(255, 255, 255, 0.6);
}
.separator {
  float: left;
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 60px 0;
}
@media (max-width: 639px) {
  .separator {
    width: 90%;
    margin: 20px 5%;
  }
}
.footpart {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 639px) {
  .footpart {
    margin-bottom: 40px;
  }
  .footpart:first-child {
    margin-top: 40px;
  }
}
.vcard {
  float: left;
  position: relative;
}
.vcard .service_linkedin {
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: 32px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/social-linkedin.svg);
}
.vcard .service_linkedin:hover,
.vcard .service_linkedin:focus {
  opacity: 0.6;
}
#services {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.search {
  float: left;
  width: 100%;
  max-width: 240px;
  position: relative;
  margin-top: 17px;
}
.search__input {
  float: left;
  width: 100%;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 100px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  line-height: 1.25;
  padding: 0 32px 0 12px;
}
.search__input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.search__submit {
  position: absolute;
  right: 10px;
  top: 4px;
  z-index: 2;
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/search-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
.toplink {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 2;
  width: 30px;
  height: 40px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-top-white.svg);
  cursor: pointer;
}
@media (max-width: 639px) {
  .toplink {
    top: 40px;
  }
}
.pfad {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.pfad a {
  float: left;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  display: none;
}
.pfad a:hover,
.pfad a:focus {
  color: #000;
}
.pfad a + a {
  display: block;
}
.pfad a + a + a {
  display: none;
}
.pager {
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 20px;
  justify-content: space-between;
  display: none;
}
body.sub3 .pager {
  display: flex;
}
@media (max-width: 639px) {
  .pager {
    display: none !important;
  }
}
.pager__link {
  float: left;
  padding-left: 40px;
  text-decoration: none;
  cursor: pointer;
  background-size: 24px 18px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-blue.svg);
}
.pager__link:hover,
.pager__link:focus {
  color: #000;
  background-image: url(/images/arrow-left-black.svg);
}
.pager__link.pager__link--next {
  background-image: url(/images/arrow-right-blue.svg);
  padding-left: 0;
  padding-right: 40px;
  background-position: 100% 5px;
}
.pager__link.pager__link--next:hover,
.pager__link.pager__link--next:focus {
  background-image: url(/images/arrow-right-black.svg);
}
.root-video {
  float: left;
  width: 100%;
}
a {
  color: #0078ba;
  text-decoration: underline;
}
a:hover,
a:focus {
  text-decoration: none;
}
h1,
h2 {
  font-size: 30px;
  line-height: 1.2;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.28em;
}
@media (max-width: 639px) {
  h1,
  h2 {
    letter-spacing: 0;
  }
}
h3 {
  font-size: 22px;
  line-height: 1.27272727;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.side {
  display: flex;
  flex-wrap: wrap;
}
.farnorth {
  margin-top: -160px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .farnorth {
    margin-top: -50px;
  }
}
.side .flat {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.22222222;
  color: #0078ba;
}
.side .flat a {
  text-decoration: none;
}
.side .flat a:hover,
.side .flat a:focus {
  text-decoration: underline;
}
.side .flat .part {
  margin: 0 !important;
  width: 100% !important;
}
.farnorth .unit .body {
  display: flex;
  flex-wrap: wrap;
}
.farnorth .unit .pict .cb-image-figure {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.farnorth .unit .pict .cb-image-caption {
  position: static;
  background: none;
  color: #000;
  transition: all 0.2s;
}
.farnorth .unit .pict .cb-image-caption:after {
  display: none;
}
.farnorth .unit .pict img {
  filter: brightness(0);
  transition: all 0.2s;
}
@media (max-width: 639px) {
  .farnorth .unit .pict img {
    display: none;
  }
}
.farnorth .unit .pict a {
  height: 100%;
  background-color: rgba(230, 230, 230, 0.9);
  transition: all 0.2s;
}
.farnorth .unit .pict a:hover,
.farnorth .unit .pict a:focus {
  background-color: rgba(255, 200, 0, 0.9);
}
.farnorth .unit .pict a:hover .cb-image-caption,
.farnorth .unit .pict a:focus .cb-image-caption {
  color: #0078ba;
}
.farnorth .unit .pict a:hover img,
.farnorth .unit .pict a:focus img {
  filter: brightness(1);
}
.unit.form {
  font-size: 18px;
  line-height: 1.22222222;
}
div.pict .cb-image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  padding: 20px 26px;
  background-color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
div.pict .cb-image-caption:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cross-blue.svg);
  transform: scale(0);
  opacity: 0;
  transition: none;
}
div.pict a:hover .cb-image-caption,
div.pict a:focus .cb-image-caption {
  background-color: rgba(255, 200, 0, 0.8);
  height: 100%;
}
div.pict a:hover .cb-image-caption:after,
div.pict a:focus .cb-image-caption:after {
  transition: all 0.3s 0.05s;
  transform: scale(1);
  opacity: 1;
}
div.pict a[title="Zoom"] {
  background-color: #ffc800;
}
div.pict a[title="Zoom"] img {
  transition: all 0.4s;
}
div.pict a[title="Zoom"]:hover img,
div.pict a[title="Zoom"]:focus img {
  opacity: 0.2;
}
.togglenavigation {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 2001;
  cursor: pointer;
  font-size: 22px;
  line-height: 1.18181818;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  box-sizing: border-box;
  background-color: #fff;
  padding: 8px 46px 6px 10px;
}
body.cb-toggle-target-active .togglenavigation {
  color: transparent;
  background-color: transparent;
}
@media (max-width: 1024px) {
  .togglenavigation {
    right: 20px;
  }
}
@media (max-width: 639px) {
  .togglenavigation {
    text-indent: -9999px;
    height: 22px;
    padding: 0;
    width: 30px;
    right: 5%;
    background: none;
    top: 20px;
  }
  .togglenavigation .tline--1 {
    top: 0;
  }
  .togglenavigation .tline--4 {
    bottom: 0;
  }
}
.tline {
  position: absolute;
  top: 9px;
  right: 0;
  width: 30px;
  height: 4px;
  background-color: #0078ba;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .tline {
  background-color: #000;
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 9px;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #0078ba;
}
.mobile-navigation div.sub1 {
  float: right;
  box-sizing: border-box;
  margin: 140px 0 30px;
  border-right: 40px solid transparent;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: right;
}
@media (max-width: 1024px) {
  .mobile-navigation div.sub1 {
    border-right-width: 20px;
    max-width: 300px;
  }
}
@media (max-width: 639px) {
  .mobile-navigation div.sub1 {
    border-right-width: 5vw;
    margin-top: 100px;
  }
}
.mobile-navigation div.sub1 > .item {
  width: 100%;
  box-sizing: border-box;
  padding-right: 46px;
  margin: 14px 0;
}
.mobile-navigation div.sub1 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.28em;
  text-decoration: none;
}
.mobile-navigation div.sub2 {
  text-align: right;
}
.mobile-navigation div.sub2 > .item {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-bottom: -1px;
  padding: 6px 0;
}
.mobile-navigation div.sub2 > .item.init {
  border-top: none;
}
.mobile-navigation div.sub2 > .item.exit {
  border-bottom: none;
}
.mobile-navigation div.sub2 > .item > .menu {
  text-decoration: none;
  white-space: nowrap;
}
@media (max-width: 639px) {
  .mobile-navigation div.sub2 > .item > .menu {
    white-space: initial;
  }
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item > .menu {
  transform: scale(1);
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  height: 24px;
  background-size: 12px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.desk {
  max-width: 90%;
}
#home {
  height: 50px;
  margin: 20px 0 20px 5%;
}
@media (max-width: 1024px) {
  #view .side .flat .part.text {
    margin-top: 20px !important;
  }
}
/*# sourceMappingURL=./screen-small.css.map */